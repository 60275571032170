import './index.css'

const Database = () => {
  return (
   <>
      <div>Database</div>   
   </>
  )
}

export default Database

